// https://tailwindcss.com/docs/using-with-preprocessors#build-time-imports
// not sure about best ordering viz tailwind classes not being clobbered and
// of hot reloading/compiling
// NOTE: All overrides have to be "important" right now, because we have `important: true`
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

$abstract_colors: 'stratos',
                  'dark-blue',
                  'true-blue',
                  'teal-blue',
                  'tropical-blue',
                  'sky-blue',
                  'green',
                  'moss-green',
                  'violet',
                  'purple',
                  'red',
                  'orange',
                  'amber',
                  'yellow',
                  'gray';

$abstract_weights: ();
$i: 100;
@while $i < 1000 {
  $abstract_weights: append($abstract_weights, $i);
  $i: $i + 100
}

$color_pairings: (
                  'true-blue-200': 'tw-text-black',
                  'true-blue-800': 'tw-text-white',
                  'teal-blue': 'tw-text-black',
                  'tropical-blue': 'tw-text-black',
                  'sky-blue': 'tw-text-black',
                  'moss-green': 'tw-text-black',
                  'amber': 'tw-text-black',
                  'gray': 'tw-text-black',
                );
@each $color in $abstract_colors {
  $color_pairings: map-merge(($color: 'tw-text-white'), $color_pairings);
}

@layer components {
  @mixin apply-bg-color-and-weights($color) {
    &\:tw-bg-k-#{$color} {
      @apply tw-bg-k-#{$color};

      @each $weight in $abstract_weights {
        &-#{$weight} {
          @apply tw-bg-k-#{$color}-#{$weight} #{!important};
        }
      }
    }

    &\:hover\:tw-bg-k-#{$color} {
      &:hover {
        @apply tw-bg-k-#{$color} #{!important};
      }

      @each $weight in $abstract_weights {
        &-#{$weight} {
          &:hover {
            @apply tw-bg-k-#{$color}-#{$weight} #{!important};
          }
        }
      }
    }

    &\:focus\:tw-bg-k-#{$color} {
      &:focus {
        @apply tw-bg-k-#{$color} #{!important};
      }

      @each $weight in $abstract_weights {
        &-#{$weight} {
          &:focus {
            @apply tw-bg-k-#{$color}-#{$weight} #{!important};
          }
        }
      }
    }
  }

  @mixin apply-translate() {
    &\:tw-translate-x-0 {
      @apply tw-translate-x-0 #{!important};
    }

    &\:tw-translate-x-full {
      @apply tw-translate-x-full #{!important};
    }

    &\:hover\:tw-translate-x-0 {
      &:hover {
        @apply tw-translate-x-0 #{!important};
      }
    }

    &\:hover\:tw-translate-x-full {
      &:hover {
        @apply tw-translate-x-full #{!important};
      }
    }

    &\:focus\:tw-translate-x-0 {
      &:focus {
        @apply tw-translate-x-0 #{!important};
      }
    }

    &\:focus\:tw-translate-x-full {
      &:focus {
        @apply tw-translate-x-full #{!important};
      }
    }
  }

  @mixin apply-ring() {
    &\:tw-ring-0 {
      @apply tw-ring-0 #{!important};
    }

    &\:tw-ring {
      @apply tw-ring #{!important};
    }

    &\:hover\:tw-ring-0 {
      &:hover {
        @apply tw-ring-0 #{!important};
      }
    }

    &\:hover\:tw-ring {
      &:hover {
        @apply tw-ring #{!important};
      }
    }
  }

  @mixin apply-switch-color($color, $important: true) {
    $i: if($important, !important, null);
    @apply tw-bg-k-#{$color}-200 tw-border-k-#{$color}-300 #{$i};

    &:after {
      @apply tw-bg-k-#{$color}-400 tw-border-k-#{$color}-400 #{$i};
    }
  }

  @mixin apply-switch-medium-color($color, $important: true) {
    $i: if($important, !important, null);
    @apply tw-bg-k-#{$color}-300 tw-border-k-#{$color}-400 #{$i};

    &:after {
      @apply tw-bg-k-#{$color}-500 tw-border-k-#{$color}-500 #{$i};
    }
  }

  @mixin apply-switch-heavy-color($color, $important: true) {
    $i: if($important, !important, null);
    @apply tw-bg-k-#{$color}-400 tw-border-k-#{$color}-600 #{$i};

    &:after {
      @apply tw-bg-k-#{$color}-800 tw-border-k-#{$color}-800 #{$i};
    }
  }

  .tw-peer {
    &:checked {
      & ~ .peer-checked {
        @each $color in $abstract_colors {
          @include apply-bg-color-and-weights($color);
        }

        @include apply-translate;
        @include apply-ring;

        &\:focus:focus {
          @each $color in $abstract_colors {
            @include apply-bg-color-and-weights($color);
          }

          @include apply-translate;
          @include apply-ring;
        }
      }
    }

    &:focus {
      & ~ .peer-focused {
        @each $color in $abstract_colors {
          @include apply-bg-color-and-weights($color);
        }

        @include apply-translate;
        @include apply-ring;
      }

      &:checked {
        & ~ .peer-focus\:checked {
          @each $color in $abstract_colors {
            @include apply-bg-color-and-weights($color);
          }

          @include apply-translate;
          @include apply-ring;
        }
      }
    }

    &:disabled {
      & ~ .peer-disabled {
        @each $color in $abstract_colors {
          @include apply-bg-color-and-weights($color);
        }

        @include apply-translate;
        @include apply-ring;

        &\:focus:focus {
          @each $color in $abstract_colors {
            @include apply-bg-color-and-weights($color);
          }

          @include apply-translate;
          @include apply-ring;
        }
      }
    }

    &:checked:disabled {
      & ~ .peer-checked\:disabled {
        @each $color in $abstract_colors {
          @include apply-bg-color-and-weights($color);
        }

        @include apply-translate;
        @include apply-ring;

        &\:focus:focus {
          @each $color in $abstract_colors {
            @include apply-bg-color-and-weights($color);
          }

          @include apply-translate;
          @include apply-ring;
        }
      }
    }
  }

  .tw-form-switch {
    @apply tw-border tw-border-solid tw-h-2/3 tw-rounded-full tw-relative;
    @apply tw-flex tw-flex-row tw-items-center tw-justify-start;
    @apply tw-bg-k-gray-200 tw-border-k-gray-300;
    aspect-ratio: 2.5 / 1;

    &:before, &:after {
      content: '';
      flex-grow: 0.001;
      flex-shrink: 1;
      flex-basis: auto;
      @apply tw-transition-all tw-duration-300;
    }

    .tw-form-switch-input:checked + &:before {
      flex-grow: 1;
    }

    &:after {
      @apply tw-border tw-border-solid tw-rounded-full tw-shadow;
      @apply tw-flex-grow-0;
      @apply tw-bg-white tw-border-k-gray-400;
      height: 150%;
      aspect-ratio: 1 / 1;
    }

    label:focus-within & {
      @apply tw-border-2 tw-border-k-gray-400;

      &:after {
        height: 175%;
      }
    }

    @each $color in $abstract_colors {
      &.tw-k-#{$color}, &.#{$color}-switch {
        @if $color == 'gray' {
          @include apply-switch-medium-color($color);
        } @else {
          @include apply-switch-color($color);
        }

        .tw-form-switch-input:checked + & {
          @if $color == 'gray' {
            @include apply-switch-heavy-color($color);
          } @else {
            @include apply-switch-medium-color($color);
          }
        }
      }
    }

    @each $color in $abstract_colors {
      &.checked\:tw-k-#{$color}, &.tw-form-switch-checked-#{$color} {
        .tw-form-switch-input:checked + & {
          @if $color == 'gray' {
            @include apply-switch-heavy-color($color);
          } @else {
            @include apply-switch-medium-color($color);
          }
        }
      }
    }

    .tw-form-switch-input:disabled + & {
      @include apply-switch-color('gray');

      .tw-form-switch-input:checked + & {
        @include apply-switch-medium-color('gray');
      }
    }

    @each $color in $abstract_colors {
      &.disabled\:tw-k-#{$color}, &.tw-form-switch-disabled-#{$color} {
        .tw-form-switch-input:disabled + & {
          @include apply-switch-color($color);
        }
      }

      &.checked\:disabled\:tw-k-#{$color}, &.tw-form-switch-checked-disabled-#{$color} {
        .tw-form-switch-input:checked:disabled + & {
          @include apply-switch-color($color);
        }
      }
    }
  }

  .tw-box-border-all {
    &, & *, &:after, & *:after {
      @apply tw-box-border;
    }
  }

  .tw-mce-editor {
    @apply tw-rounded;

    .mce-btn {
      @apply tw-bg-transparent;
    }

    @each $color, $text in $color_pairings {
      @apply tw-bg-k-#{$color} tw-border-k-#{$color};

      &.tw-mce-editor-#{$color} {
        &, .mce-btn {
          @apply tw-bg-k-#{$color} tw-border-k-#{$color} #{$text};
        }

        .mce-container {
          &[role=group] {
            @apply tw-border-k-#{$color};
          }

          &.mce-toolbar-grp, &.mce-statusbar {
            @apply tw-bg-k-#{$color} tw-border-k-#{$color} #{$text};

            &, & * {
              @apply #{$text};
            }
          }
        }
      }
    }
  }

  .tw-has-error {
    @at-root #{selector-append(input, &)}, & input, #{selector-append(select, &)}, & select {
      @apply tw-border-k-red-500 #{!important};
    }

    @at-root #{selector-append(label, &)}, & label {
      @apply tw-text-k-red-500 #{!important};
    }
  }
}

input.tw-file-input[type=file] {
  padding-right: 8rem;
  position: relative;
  &::-webkit-file-upload-button {
    -webkit-margin-start: 1rem;
    -webkit-margin-end: 0;
    font: inherit;
    background: #1f2937;
    border: 0;
    color: #fff;
    cursor: pointer;
    font-size: .875rem;
    font-weight: 500;
    margin-inline-start: 1rem;
    margin-inline-end: 0;
    padding: .625rem 1rem .625rem 1rem;
    position: absolute;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    right: 0;
    top: 0;
    height: 100%;
  }

  &::file-selector-button {
    -webkit-margin-start: 1rem;
    -webkit-margin-end: 0;
    font: inherit;
    background: #1f2937;
    border: 0;
    color: #fff;
    cursor: pointer;
    font-size: .875rem;
    font-weight: 500;
    margin-inline-start: 1rem;
    margin-inline-end: 0;
    padding: .625rem 1rem .625rem 1rem;
    position: absolute;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    right: 0;
    top: 0;
    height: 100%;
    &:hover {
      background: #374151
    }
  }

  &::-ms-browse {
    font: inherit;
    background: #1f2937;
    border: 0;
    color: #fff;
    cursor: pointer;
    font-size: .875rem;
    font-weight: 500;
    margin-inline-start: 1rem;
    margin-inline-end: 0;
    padding: .625rem 1rem .625rem 1rem;
    position: absolute;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    right: 0;
    top: 0;
    height: 100%;
  }
}
